import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";

import api from "../api/axiosConfig";

export const Login = ({ setIsAuthenticated, setUserRole, API_URL }) => {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const navigate = useNavigate();
  const [errorMessage, setErrorMessage] = useState();
  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      await api
        .post(`/login`, {
          username,
          password,
        })
        .then((response) => {
          console.log(response);
          if (response.status === 200) {
            setIsAuthenticated(true);
            setUserRole(response.data.user.role);
            navigate("/dashboard");
          } else if (response.status === 401) {
            setErrorMessage(response.data.message);
          } else if (response.status === 400) {
            setErrorMessage(response.data.message);
          } else {
            setErrorMessage(response.data.message);
          }
        })
        .catch((error) => {
          console.error(error);
          setErrorMessage(error.response.data.message);
        });
    } catch (error) {
      console.error("Login failed:", error);
    }
  };

  return (
    <div className="max-w-sm mx-auto mt-10 p-6 bg-white dark:bg-gray-800 rounded-lg shadow-md">
      <h2 className="text-3xl font-bold mb-6 text-gray-900 dark:text-gray-100">
        Login
      </h2>
      <p className="text-red-500 mb-6">{errorMessage}</p>

      <form onSubmit={handleSubmit} className="space-y-4">
        <input
          type="text"
          value={username}
          onChange={(e) => setUsername(e.target.value)}
          placeholder="Username"
          className="w-full p-3 border border-gray-300 rounded-md dark:bg-gray-800 dark:border-gray-600 dark:text-gray-100 focus:outline-none focus:ring-2 focus:ring-blue-500 dark:focus:ring-blue-400"
        />
        <input
          type="password"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          placeholder="Password"
          className="w-full p-3 border border-gray-300 rounded-md dark:bg-gray-800 dark:border-gray-600 dark:text-gray-100 focus:outline-none focus:ring-2 focus:ring-blue-500 dark:focus:ring-blue-400"
        />
        <button
          type="submit"
          className="w-full p-3 bg-green-500 text-white rounded-md hover:bg-green-600 dark:bg-green-600 dark:hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-green-400"
        >
          Login
        </button>
      </form>
      <p className="mt-6 text-center text-gray-700 dark:text-gray-300">
        Don't have an account?{" "}
        <Link
          to="/register"
          className="text-blue-500 dark:text-blue-400 hover:underline"
        >
          Register
        </Link>
      </p>
      <p className="mt-6 text-center text-gray-700 dark:text-gray-300">
        <Link
          to="/forgot-password"
          className="text-blue-500 dark:text-red-500 hover:underline"
        >
          {" "}
          Forgot Password?
        </Link>
      </p>
    </div>
  );
};
