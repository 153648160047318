import React, { useEffect, useState } from 'react';
import api from "../api/axiosConfig";


export const QrCode = () => {
  const [qrCodeUrl, setQrCodeUrl] = useState(null);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchQRCode = async () => {
      try {
        const response = await api.get(`/qr-code`, {
          responseType: 'blob',
          withCredentials: true // Include this if you need to send cookies
        });

        if (response.status === 200) {
          const url = URL.createObjectURL(response.data);
          setQrCodeUrl(url);
          setError(null);
        } else {
          setError("Failed to fetch QR code");
        }
      } catch (error) {
        if (error.response && error.response.status === 404) {
          setError("You are logged in!");
        } else {
          setError("Error fetching QR code: " + error.message);
        }
      }
    };

    fetchQRCode();
    const intervalId = setInterval(fetchQRCode, 30000); // Check every 30 seconds

    return () => {
      clearInterval(intervalId);
      if (qrCodeUrl) {
        URL.revokeObjectURL(qrCodeUrl);
      }
    };
  }, []);

  return (
    <div className="max-w-md mx-auto p-4 bg-white dark:bg-gray-800 rounded shadow-md">
      {error ? (
        <p className="text-red-500">{error}</p>
      ) : qrCodeUrl ? (
        <img
          src={qrCodeUrl}
          alt="WhatsApp QR Code"
          className="w-full h-auto rounded border border-gray-300 dark:border-gray-600"
        />
      ) : (
        <p className="text-gray-600 dark:text-gray-400">Loading QR Code...</p>
      )}
    </div>
  );
}
