import React, { useEffect, useState } from 'react';
import { useTheme } from '../ThemeContext';
import api from "../api/axiosConfig";

export const UserConversation = () => {
  const [conversations, setConversations] = useState([]);
  const [selectedUserId, setSelectedUserId] = useState(null);
  const [conversation, setConversation] = useState(null);
  const { isDarkMode } = useTheme();

  useEffect(() => {
    fetchConversations();
  }, []);

  useEffect(() => {
    if (selectedUserId) {
      fetchConversation(selectedUserId);
    }
  }, [selectedUserId]);

  const fetchConversations = async () => {
    try {
      const response = await api.get(`/conversations`);
      setConversations(response.data);
    } catch (error) {
      console.error('Error fetching conversations:', error);
    }
  };

  const fetchConversation = async (userId) => {
    try {
      const response = await api.get(`/conversation/${userId}`);
      setConversation(response.data);
    } catch (error) {
      console.error('Error fetching conversation:', error);
    }
  };

  return (
    <div className={`max-w-5xl mx-auto mt-10 p-6 ${isDarkMode ? 'bg-gray-900 text-white' : 'bg-white text-gray-900'} rounded-lg shadow-lg`}>
      <h2 className="text-3xl font-bold mb-6">User Conversations</h2>
      <div className="flex">
        {/* Sidebar for users */}
        <div className={`w-1/3 pr-4 border-r ${isDarkMode ? 'border-gray-700' : 'border-gray-300'}`}>
          <h3 className="text-xl font-semibold mb-4">Users</h3>
          <ul>
            {conversations.map((conv) => (
              <li
                key={conv.userId}
                className={`cursor-pointer p-3 mb-2 rounded-md transition-colors ${selectedUserId === conv.userId ? 'bg-blue-600 text-white' : isDarkMode ? 'hover:bg-gray-800' : 'hover:bg-gray-200'}`}
                onClick={() => setSelectedUserId(conv.userId)}
              >
                {conv.userId}
              </li>
            ))}
          </ul>
        </div>

        {/* Conversation details */}
        <div className="w-2/3 pl-4">
          <h3 className="text-xl font-semibold mb-4">Conversation</h3>
          {conversation ? (
            <div className={`border rounded-md p-4 ${isDarkMode ? 'bg-gray-800 border-gray-700' : 'bg-gray-100 border-gray-300'}`}>
              {conversation.messages.map((message, index) => (
                <div key={index} className={`mb-3 ${message.role === 'user' ? 'text-green-500' : 'text-blue-500'}`}>
                  <strong className="font-bold">{message.role}:</strong> {message.content}
                </div>
              ))}
            </div>
          ) : (
            <p className="text-lg text-gray-500">Select a user to view their conversation</p>
          )}
        </div>
      </div>
    </div>
  );
};
