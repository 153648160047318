import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import api from "../api/axiosConfig";
import { QrCode } from './QrCode';


export const AdminDashboard = ({ userRole }) => {
  const [pendingUsers, setPendingUsers] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    if (userRole !== 'admin') {
      navigate('/');
    } else {
      fetchPendingUsers();
    }
  }, [userRole, navigate]);

  const fetchPendingUsers = async () => {
    try {
      const response = await api.get(`/pending-users`,{
        withCredentials: true // Include this if you need to send cookies
      });
      setPendingUsers(response.data);
    } catch (error) {
      console.error('Error fetching pending users:', error);
    }
  };

  const handleApproval = async (userId, status) => {
    try {
      await api.post(`/approve-user/${userId}`, { status },{
        withCredentials: true // Include this if you need to send cookies
      });
      fetchPendingUsers();
    } catch (error) {
      console.error('Error approving/rejecting user:', error);
    }
  };

  return (
    <div className="max-w-4xl mx-auto mt-10 p-4 bg-white dark:bg-gray-900 text-gray-900 dark:text-gray-100 rounded shadow">
      <h2 className="text-2xl font-bold mb-6">Admin Dashboard</h2>
      <QrCode />

      <h3 className="text-xl mb-4">Pending Users</h3>
      <ul className="space-y-4">
        {pendingUsers.map((user) => (
          <li
            key={user._id}
            className="p-4 bg-gray-100 dark:bg-gray-800 rounded flex justify-between items-center"
          >
            <span>{user.username}</span>
            <div className="space-x-2">
              <button
                onClick={() => handleApproval(user._id, 'approved')}
                className="px-4 py-2 bg-green-500 text-white rounded hover:bg-green-600 dark:bg-green-600 dark:hover:bg-green-700"
              >
                Approve
              </button>
              <button
                onClick={() => handleApproval(user._id, 'rejected')}
                className="px-4 py-2 bg-red-500 text-white rounded hover:bg-red-600 dark:bg-red-600 dark:hover:bg-red-700"
              >
                Reject
              </button>
            </div>
          </li>
        ))}
      </ul>
    </div>
  );
};
