import React, { useEffect, useState } from 'react';
import api from "../api/axiosConfig";
import { useTheme } from '../ThemeContext'; // Import the theme context
import { FileImport } from './FileImport'; // Assuming you have a FileImport component

export const TrainingDataManager = () => {

  const [trainingData, setTrainingData] = useState([]);
  const [newText, setNewText] = useState('');
  const [newCategory, setNewCategory] = useState('');
  const { isDarkMode } = useTheme(); // Get the current theme

  useEffect(() => {
    fetchTrainingData();
  }, []);

  const fetchTrainingData = async () => {
    try {
      const response = await api.get(`/training-data`);
      setTrainingData(response.data);
    } catch (error) {
      console.error('Error fetching training data:', error);
    }
  };

  const handleAddTrainingData = async (e) => {
    e.preventDefault();
    try {
      await api.post(`/train`, { text: newText, category: newCategory });
      setNewText('');
      setNewCategory('');
      fetchTrainingData();
    } catch (error) {
      console.error('Error adding training data:', error);
    }
  };

  const handleDeleteTrainingData = async (id) => {
    try {
      await api.delete(`/training-data/${id}`);
      fetchTrainingData();
    } catch (error) {
      console.error('Error deleting training data:', error);
    }
  };

  const handleImport = async (data) => {
    if (Array.isArray(data.nlp_training_data)) {
      for (const item of data.nlp_training_data) {
        try {
          await api.post(`/train`, { text: item.text, category: item.category });
        } catch (error) {
          console.error('Error importing training data:', error);
        }
      }
      fetchTrainingData();
    } else {
      alert('Invalid import data format. Please ensure your JSON file contains an "nlp_training_data" array.');
    }
  };

  return (
    <div
      className={`max-w-4xl mx-auto mt-10 p-6 ${
        isDarkMode ? 'bg-gray-900 text-white' : 'bg-white text-gray-900'
      } rounded-lg shadow-lg`}
    >
      <h2 className="text-3xl font-bold mb-6">Manage Training Data</h2>
      <FileImport onImport={handleImport} type="training" />
      <form onSubmit={handleAddTrainingData} className="mb-8 mt-4">
        <input
          type="text"
          value={newText}
          onChange={(e) => setNewText(e.target.value)}
          placeholder="Text"
          className={`w-full p-3 mb-4 border rounded-md ${
            isDarkMode ? 'bg-gray-800 border-gray-700 text-gray-100' : 'bg-gray-100 border-gray-300'
          }`}
        />
        <input
          type="text"
          value={newCategory}
          onChange={(e) => setNewCategory(e.target.value)}
          placeholder="Category"
          className={`w-full p-3 mb-4 border rounded-md ${
            isDarkMode ? 'bg-gray-800 border-gray-700 text-gray-100' : 'bg-gray-100 border-gray-300'
          }`}
        />
        <button
          type="submit"
          className={`w-full p-3 rounded-md text-white ${
            isDarkMode ? 'bg-green-600 hover:bg-green-700' : 'bg-green-500 hover:bg-green-600'
          }`}
        >
          Add Training Data
        </button>
      </form>
      <ul>
        {trainingData.map((data) => (
          <li
            key={data._id}
            className={`mb-4 p-4 border rounded-md ${
              isDarkMode ? 'bg-gray-800 border-gray-700' : 'bg-gray-100 border-gray-300'
            }`}
          >
            <p className="mb-2"><strong>Text:</strong> {data.text}</p>
            <p className="mb-2"><strong>Category:</strong> {data.category}</p>
            <button
              onClick={() => handleDeleteTrainingData(data._id)}
              className={`p-2 rounded-md text-white ${
                isDarkMode ? 'bg-red-600 hover:bg-red-700' : 'bg-red-500 hover:bg-red-600'
              }`}
            >
              Delete
            </button>
          </li>
        ))}
      </ul>
    </div>
  );
};
