import { faMoon, faSun } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useTheme } from "../ThemeContext";

export const Navbar = ({ setIsAuthenticated, toggleTheme }) => {
  const { isDarkMode } = useTheme();

  return (
    <header
      className={`navbar flex justify-between items-center p-4 ${
        isDarkMode ? "bg-gray-800 text-gray-100" : "bg-gray-200 text-gray-900"
      }`}
    >
      <h1 className="text-xl font-bold">Crazy Inventor WaBot</h1>
      <button
        onClick={toggleTheme}
        className={`p-2 rounded transition-colors duration-300 ${
          isDarkMode
            ? "bg-gray-700 hover:bg-gray-600"
            : "bg-blue-500 hover:bg-blue-600"
        }`}
      >
        <FontAwesomeIcon icon={isDarkMode ? faSun : faMoon} />
      </button>
    </header>
  );
};
