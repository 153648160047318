import React, { useEffect, useState } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import api from '../../api/axiosConfig';

export const ResetPassword = () => {
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [message, setMessage] = useState('');
  const [isLoading, setIsLoading] = useState(true);
  const [isTokenValid, setIsTokenValid] = useState(false);
  const { token } = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    const checkToken = async () => {
      try {
        const response = await api.get(`/check-reset-token/${token}`);
        setIsTokenValid(response.data.isValid);
      } catch (error) {
        setMessage(error.response?.data?.message || 'Token is invalid or has expired');
        setIsTokenValid(false);
      } finally {
        setIsLoading(false);
      }
    };

    checkToken();
  }, [token]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (password !== confirmPassword) {
      setMessage('Passwords do not match');
      return;
    }
    try {
      const response = await api.post(`/reset-password/${token}`, { password, confirmPassword });
      setMessage(response.data.message);
      // Redirect to login page after successful password reset
      setTimeout(() => navigate('/login'), 3000);
    } catch (error) {
      setMessage(error.response?.data?.message || 'An error occurred');
    }
  };

  if (isLoading) {
    return <div className="text-center mt-10">Validating token...</div>;
  }

  if (!isTokenValid) {
    return (
      <div className="max-w-sm mx-auto mt-10 p-6 bg-white dark:bg-gray-800 rounded-lg shadow-md">
        <h2 className="text-3xl font-bold mb-6 text-gray-900 dark:text-gray-100">Reset Password</h2>
        <p className="text-red-500 dark:text-red-400">{message}</p>
        <p className="mt-4 text-center">
          <Link to="/forgot-password" className="text-blue-500 dark:text-blue-400 hover:underline">
            Request a new password reset
          </Link>
        </p>
      </div>
    );
  }

  return (
    <div className="max-w-sm mx-auto mt-10 p-6 bg-white dark:bg-gray-800 rounded-lg shadow-md">
      <h2 className="text-3xl font-bold mb-6 text-gray-900 dark:text-gray-100">Reset Password</h2>
      {message && <p className="mb-4 text-sm text-green-600 dark:text-green-400">{message}</p>}
      <form onSubmit={handleSubmit} className="space-y-4">
        <input
          type="password"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          placeholder="New password"
          required
          className="w-full p-3 border border-gray-300 rounded-md dark:bg-gray-700 dark:border-gray-600 dark:text-gray-100 focus:outline-none focus:ring-2 focus:ring-blue-500 dark:focus:ring-blue-400"
        />
        <input
          type="password"
          value={confirmPassword}
          onChange={(e) => setConfirmPassword(e.target.value)}
          placeholder="Confirm new password"
          required
          className="w-full p-3 border border-gray-300 rounded-md dark:bg-gray-700 dark:border-gray-600 dark:text-gray-100 focus:outline-none focus:ring-2 focus:ring-blue-500 dark:focus:ring-blue-400"
        />
        <button
          type="submit"
          className="w-full p-3 bg-blue-500 text-white rounded-md hover:bg-blue-600 dark:bg-blue-600 dark:hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-400"
        >
          Reset Password
        </button>
      </form>
      <p className="mt-6 text-center text-gray-700 dark:text-gray-300">
        Remember your password?{" "}
        <Link to="/login" className="text-blue-500 dark:text-blue-400 hover:underline">
          Login
        </Link>
      </p>
    </div>
  );
};