import React, { useEffect, useState } from 'react';
import { useTheme } from '../ThemeContext';

import api from "../api/axiosConfig";

export const UserProfiles = () => {
  const [profiles, setProfiles] = useState([]);
  const { isDarkMode } = useTheme();

  useEffect(() => {
    fetchProfiles();
  }, []);

  const fetchProfiles = async () => {
    try {
      const response = await api.get(`/user-profiles`);
      setProfiles(response.data);
    } catch (error) {
      console.error('Error fetching user profiles:', error);
    }
  };

  const getMissingFields = (profile) => {
    const missing = [];
    if (!profile.name) missing.push('Name');
    if (!profile.age) missing.push('Age');
    if (!profile.location) missing.push('Location');
    if (!profile.interests || profile.interests.length === 0) missing.push('Interests');
    return missing;
  };

  return (
    <div className={`max-w-4xl mx-auto mt-10 p-6 ${isDarkMode ? 'bg-gray-900 text-white' : 'bg-white text-gray-900'}`}>
      <h2 className="text-3xl font-bold mb-6">User Profiles</h2>
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
        {profiles.map((profile) => {
          const missingFields = getMissingFields(profile);
          return (
            <div key={profile.userId} className={`p-6 rounded-lg shadow-lg ${isDarkMode ? 'bg-gray-800 border border-gray-700' : 'bg-gray-100 border border-gray-300'}`}>
              <h3 className="text-2xl font-semibold mb-3">User: {profile.userId}</h3>
              <p className="mb-2"><strong className="font-medium">Name:</strong> {profile.name || 'Not provided'}</p>
              <p className="mb-2"><strong className="font-medium">Age:</strong> {profile.age || 'Not provided'}</p>
              <p className="mb-2"><strong className="font-medium">Location:</strong> {profile.location || 'Not provided'}</p>
              <p className="mb-2"><strong className="font-medium">Interests:</strong> {profile.interests?.length ? profile.interests.join(', ') : 'None provided'}</p>
              <p className="mb-2"><strong className="font-medium">Last Updated:</strong> {new Date(profile.lastUpdated).toLocaleString()}</p>
              {missingFields.length > 0 && (
                <p className="mt-2 text-yellow-500 font-medium">
                  <strong>Missing information:</strong> {missingFields.join(', ')}
                </p>
              )}
            </div>
          );
        })}
      </div>
    </div>
  );
};
