import React, { useEffect, useState } from 'react';
import api from "../api/axiosConfig";
import { useTheme } from '../ThemeContext'; // Import the theme context
import { FileImport } from './FileImport';


export const ResponseManager = () => {
  const [responses, setResponses] = useState([]);
  const [newKeyword, setNewKeyword] = useState('');
  const [newResponse, setNewResponse] = useState('');
  const [editingId, setEditingId] = useState(null);
  const { isDarkMode } = useTheme(); // Get the current theme

  useEffect(() => {
    fetchResponses();
  }, []);

  const fetchResponses = async () => {
    try {
      const response = await api.get(`/responses`);
      setResponses(response.data);
    } catch (error) {
      console.error('Error fetching responses:', error);
    }
  };

  const handleAddResponse = async (e) => {
    e.preventDefault();
    try {
      await api.post(`/responses`, { keyword: newKeyword, response: newResponse });
      setNewKeyword('');
      setNewResponse('');
      fetchResponses();
    } catch (error) {
      console.error('Error adding response:', error);
    }
  };

  const handleUpdateResponse = async (id) => {
    try {
      await api.put(`/responses/${id}`, { 
        keyword: responses.find(r => r._id === id).keyword,
        response: responses.find(r => r._id === id).response
      });
      setEditingId(null);
      fetchResponses();
    } catch (error) {
      console.error('Error updating response:', error);
    }
  };

  const handleDeleteResponse = async (id) => {
    try {
      await api.delete(`/responses/${id}`);
      fetchResponses();
    } catch (error) {
      console.error('Error deleting response:', error);
    }
  };

  const handleImport = async (data) => {
    if (Array.isArray(data.keyword_responses)) {
      for (const item of data.keyword_responses) {
        try {
          await api.post(`/responses`, { keyword: item.keyword, response: item.response });
        } catch (error) {
          console.error('Error importing response:', error);
        }
      }
      fetchResponses();
    } else {
      alert('Invalid import data format. Please ensure your JSON file contains a "keyword_responses" array.');
    }
  };

  return (
    <div className={`max-w-4xl mx-auto mt-10 p-6 ${isDarkMode ? 'bg-gray-900 text-white' : 'bg-white text-gray-900'} rounded-lg shadow-lg`}>
      <h2 className="text-3xl font-bold mb-6">Manage Responses</h2>
      <FileImport onImport={handleImport} type="responses" />
      <form onSubmit={handleAddResponse} className="mb-8">
        <input
          type="text"
          value={newKeyword}
          onChange={(e) => setNewKeyword(e.target.value)}
          placeholder="Keyword"
          className={`w-full p-3 mb-4 border rounded-md ${isDarkMode ? 'bg-gray-800 border-gray-700 text-gray-100' : 'bg-gray-100 border-gray-300'}`}
        />
        <textarea
          value={newResponse}
          onChange={(e) => setNewResponse(e.target.value)}
          placeholder="Response"
          className={`w-full p-3 mb-4 border rounded-md ${isDarkMode ? 'bg-gray-800 border-gray-700 text-gray-100' : 'bg-gray-100 border-gray-300'}`}
        />
        <button
          type="submit"
          className={`w-full p-3 rounded-md text-white ${isDarkMode ? 'bg-green-600 hover:bg-green-700' : 'bg-green-500 hover:bg-green-600'}`}
        >
          Add Response
        </button>
      </form>
      <ul>
        {responses.map((resp) => (
          <li key={resp._id} className={`mb-6 p-4 border rounded-md ${isDarkMode ? 'bg-gray-800 border-gray-700' : 'bg-gray-50 border-gray-300'}`}>
            {editingId === resp._id ? (
              <>
                <input
                  type="text"
                  value={resp.keyword}
                  onChange={(e) => setResponses(responses.map(r => r._id === resp._id ? {...r, keyword: e.target.value} : r))}
                  className={`w-full p-3 mb-4 border rounded-md ${isDarkMode ? 'bg-gray-800 border-gray-700 text-gray-100' : 'bg-gray-100 border-gray-300'}`}
                />
                <textarea
                  value={resp.response}
                  onChange={(e) => setResponses(responses.map(r => r._id === resp._id ? {...r, response: e.target.value} : r))}
                  className={`w-full p-3 mb-4 border rounded-md ${isDarkMode ? 'bg-gray-800 border-gray-700 text-gray-100' : 'bg-gray-100 border-gray-300'}`}
                />
                <button onClick={() => handleUpdateResponse(resp._id)} className="p-3 bg-blue-500 text-white rounded-md mr-2 hover:bg-blue-600">Save</button>
                <button onClick={() => setEditingId(null)} className="p-3 bg-gray-500 text-white rounded-md hover:bg-gray-600">Cancel</button>
              </>
            ) : (
              <>
                <p><strong>Keyword:</strong> {resp.keyword}</p>
                <p><strong>Response:</strong> {resp.response}</p>
                <button onClick={() => setEditingId(resp._id)} className="p-3 bg-yellow-500 text-white rounded-md mr-2 hover:bg-yellow-600">Edit</button>
                <button onClick={() => handleDeleteResponse(resp._id)} className="p-3 bg-red-500 text-white rounded-md hover:bg-red-600">Delete</button>
              </>
            )}
          </li>
        ))}
      </ul>
    </div>
  );
};
