import { Link, Route, Routes } from "react-router-dom";
import api from "../api/axiosConfig";
import { AdminDashboard } from "./Admin";
import AIRulesManagement from "./AIRulesManagement";
import { AllUsersImportantData } from "./ImportantData";
import { ResponseManager } from "./ResponseManager";
import { TestClassification } from "./TestClassification";
import { TrainingDataManager } from "./TrainingDataManager";
import { UserConversation } from "./UserConversation";
import { UserProfiles } from "./UserProfiles";

export const Dashboard = ({ setIsAuthenticated, userRole }) => {
  const handleLogout = async () => {
    try {
      await api.post(`/logout`);
      setIsAuthenticated(false);
    } catch (error) {
      console.error("Error logging out:", error);
    }
  };

  const handleWhatsappLogout = async () => {
    try {
      await api.post(`/whatsapp/client/logout`);
    } catch (error) {
      console.error("Error logging out from WhatsApp:", error);
    }
  };

  return (
    <div className="max-w-6xl mx-auto mt-10 p-4 bg-white dark:bg-gray-900 text-gray-900 dark:text-gray-100 rounded-lg shadow-md">
      <div className="flex justify-between items-center mb-6">
        <h1 className="text-3xl font-bold">WhatsApp Bot Dashboard</h1>
        <div>
          <button
            onClick={handleWhatsappLogout}
            className="mr-2 p-2 bg-red-500 text-white rounded hover:bg-red-600 dark:bg-red-600 dark:hover:bg-red-700"
          >
            WhatsApp Logout
          </button>
          <button
            onClick={handleLogout}
            className="p-2 bg-red-500 text-white rounded hover:bg-red-600 dark:bg-red-600 dark:hover:bg-red-700"
          >
            Logout
          </button>
        </div>
      </div>
      <nav className="mb-8">
        <ul className="flex flex-wrap space-x-4">
          <li>
            <Link
              to="/dashboard/responses"
              className="text-blue-500 hover:underline dark:text-blue-400 dark:hover:underline"
            >
              Manage Responses
            </Link>
          </li>
          <li>
            <Link
              to="/dashboard/training"
              className="text-blue-500 hover:underline dark:text-blue-400 dark:hover:underline"
            >
              Manage Training Data
            </Link>
          </li>
          <li>
            <Link
              to="/dashboard/rules"
              className="text-blue-500 hover:underline dark:text-blue-400 dark:hover:underline"
            >
              Manage Rules
            </Link>
          </li>
          <li>
            <Link
              to="/dashboard/test"
              className="text-blue-500 hover:underline dark:text-blue-400 dark:hover:underline"
            >
              Test Classification
            </Link>
          </li>
          <li>
            <Link
              to="/dashboard/conventions"
              className="text-blue-500 hover:underline dark:text-blue-400 dark:hover:underline"
            >
              Users Conventions
            </Link>
          </li>
          <li>
            <Link
              to="/dashboard/user-profiles"
              className="text-blue-500 hover:underline dark:text-blue-400 dark:hover:underline"
            >
              User Profiles
            </Link>
          </li>
          <li>
            <Link
              to="/dashboard/user-data"
              className="text-blue-500 hover:underline dark:text-blue-400 dark:hover:underline"
            >
              Important User Data
            </Link>
          </li>
          {userRole === "admin" && (
            <li>
              <Link
                to="/dashboard/admin"
                className="text-blue-500 hover:underline dark:text-blue-400 dark:hover:underline"
              >
                Admin Dashboard
              </Link>
            </li>
          )}
        </ul>
      </nav>
      <Routes>
        <Route path="responses" element={<ResponseManager />} />
        <Route path="training" element={<TrainingDataManager />} />
        <Route path="test" element={<TestClassification />} />
        <Route path="rules" element={<AIRulesManagement />} />
        <Route path="conventions" element={<UserConversation />} />
        <Route path="user-profiles" element={<UserProfiles />} />
        <Route path="user-data" element={<AllUsersImportantData />} />
        {userRole === "admin" && (
          <Route
            path="admin"
            element={<AdminDashboard userRole={userRole} />}
          />
        )}
      </Routes>
    </div>
  );
};
