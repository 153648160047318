import { useState } from "react";
import { useTheme } from "../ThemeContext"; // Import the theme context

import api from "../api/axiosConfig";



export const TestClassification = () => {
  const [testText, setTestText] = useState("");
  const [result, setResult] = useState(null);
  const { isDarkMode } = useTheme(); // Get the current theme

  const handleTestClassification = async (e) => {
    e.preventDefault();
    try {
      const response = await api.post(`/test-classification`, {
        text: testText,
      });
      setResult(response.data);
    } catch (error) {
      console.error("Error testing classification:", error);
    }
  };

  return (
    <div
      className={`max-w-4xl mx-auto mt-10 p-6 ${
        isDarkMode ? "bg-gray-900 text-white" : "bg-white text-gray-900"
      } rounded-lg shadow-lg`}
    >
      <h2 className="text-3xl font-bold mb-6">Test Classification</h2>
      <form onSubmit={handleTestClassification} className="mb-6">
        <input
          type="text"
          value={testText}
          onChange={(e) => setTestText(e.target.value)}
          placeholder="Enter text to classify"
          className={`w-full p-3 mb-4 border rounded-md ${
            isDarkMode
              ? "bg-gray-800 border-gray-700 text-gray-100"
              : "bg-gray-100 border-gray-300"
          }`}
        />
        <button
          type="submit"
          className={`w-full p-3 rounded-md text-white ${
            isDarkMode
              ? "bg-blue-600 hover:bg-blue-700"
              : "bg-blue-500 hover:bg-blue-600"
          }`}
        >
          Test Classification
        </button>
      </form>
      {result && (
        <div
          className={`mt-6 p-4 border rounded-md ${
            isDarkMode
              ? "bg-gray-800 border-gray-700"
              : "bg-gray-50 border-gray-300"
          }`}
        >
          <h3 className="text-xl font-bold mb-2">Result:</h3>
          <p className="mb-1">
            <strong>Text:</strong> {result.text}
          </p>
          <p>
            <strong>Classified as:</strong> {result.classifiedAs}
          </p>
        </div>
      )}
    </div>
  );
};
