// src/api/axiosConfig.js
import axios from 'axios';

const api = axios.create({
  baseURL: 'https://wa-api.crazy-inventor.com', // Update this to your backend URL
  withCredentials: true, // Important for handling sessions
});

// Add a request interceptor for error handling
api.interceptors.response.use(
  (response) => response,
  (error) => {
    if (error.response && error.response.status === 401) {
      // Handle unauthorized access (e.g., redirect to login)
    }
    return Promise.reject(error);
  }
);

export default api;