import axios from "axios";
import React, { useEffect, useState } from "react";
import { useTheme } from "../ThemeContext";
import api from "../api/axiosConfig";
axios.defaults.withCredentials = true;

export const AllUsersImportantData = () => {
  const [allImportantData, setAllImportantData] = useState({});
  const { isDarkMode } = useTheme();

  useEffect(() => {
    fetchAllImportantData();
  }, []);

  const fetchAllImportantData = async () => {
    try {
      const response = await api.get(`/important-data`, {
        withCredentials: true, // Include this if you need to send cookies
      });
      setAllImportantData(response.data);
    } catch (error) {
      console.error("Error fetching all important user data:", error);
    }
  };

  return (
    <div
      className={`mt-4 p-6 rounded ${
        isDarkMode ? "bg-gray-800 text-white" : "bg-white text-black"
      } shadow-lg`}
    >
      <h2 className="text-2xl font-bold mb-4">All Users Important Data</h2>
      {Object.entries(allImportantData).length > 0 ? (
        Object.entries(allImportantData).map(([userId, userData]) => (
          <div
            key={userId}
            className={`mb-6 p-4 rounded ${
              isDarkMode ? "bg-gray-700" : "bg-gray-100"
            } shadow-md`}
          >
            <h3 className="text-xl font-semibold mb-2">User: {userId}</h3>
            <ul className="space-y-2">
              {userData.map((data, index) => (
                <li
                  key={index}
                  className={`p-3 rounded ${
                    isDarkMode
                      ? "bg-gray-600 text-white"
                      : "bg-gray-200 text-black"
                  } shadow-sm`}
                >
                  <strong>{data.dataType}:</strong> {data.value}
                  <br />
                  <small>{new Date(data.timestamp).toLocaleString()}</small>
                </li>
              ))}
            </ul>
          </div>
        ))
      ) : (
        <p className="text-center text-lg font-medium">
          No important data found for any users.
        </p>
      )}
    </div>
  );
};
