import React, { useEffect, useState } from 'react';
import api from "../api/axiosConfig";
import { useTheme } from '../ThemeContext'; // Assuming you have a ThemeContext

const CATEGORIES = [
  'Response Rules',
  'Ethical Guidelines',
  'Language Style',
  'Domain-Specific Rules',
  'Interaction Patterns',
  'Keyword-Based Response'
];

const AIRulesManagement = () => {
  const [rules, setRules] = useState([]);
  const [newCategory, setNewCategory] = useState(CATEGORIES[0]);
  const [newContent, setNewContent] = useState('');
  const [editingId, setEditingId] = useState(null);
  const { isDarkMode } = useTheme(); // Get the current theme

  useEffect(() => {
    fetchRules();
  }, []);

  const fetchRules = async () => {
    try {
      const response = await api.get(`/ai-rules`);
      setRules(response.data);
    } catch (error) {
      console.error('Error fetching AI rules:', error);
    }
  };

  const handleAddRule = async (e) => {
    e.preventDefault();
    try {
      await api.post(`/ai-rules`, { category: newCategory, content: newContent });
      setNewCategory(CATEGORIES[0]);
      setNewContent('');
      fetchRules();
    } catch (error) {
      console.error('Error adding AI rule:', error);
    }
  };

  const handleUpdateRule = async (id) => {
    try {
      await api.put(`/ai-rules/${id}`, {
        category: rules.find(r => r._id === id).category,
        content: rules.find(r => r._id === id).content
      });
      setEditingId(null);
      fetchRules();
    } catch (error) {
      console.error('Error updating AI rule:', error);
    }
  };

  const handleDeleteRule = async (id) => {
    try {
      await api.delete(`/ai-rules/${id}`);
      fetchRules();
    } catch (error) {
      console.error('Error deleting AI rule:', error);
    }
  };

  return (
    <div className={`max-w-4xl mx-auto mt-10 p-6 rounded-lg shadow-md ${isDarkMode ? 'bg-gray-900 text-white' : 'bg-white text-black'}`}>
      <h2 className="text-3xl font-bold mb-6">Manage AI Rules</h2>
      <form onSubmit={handleAddRule} className="mb-8">
        <select
          value={newCategory}
          onChange={(e) => setNewCategory(e.target.value)}
          className={`w-full p-3 mb-4 border rounded-lg focus:outline-none focus:ring ${isDarkMode ? 'bg-gray-700 border-gray-600 text-white' : 'bg-gray-200 border-gray-300 text-black'}`}
        >
          {CATEGORIES.map(category => (
            <option key={category} value={category}>{category}</option>
          ))}
        </select>
        <textarea
          value={newContent}
          onChange={(e) => setNewContent(e.target.value)}
          placeholder="Rule Content"
          className={`w-full p-3 mb-4 border rounded-lg focus:outline-none focus:ring ${isDarkMode ? 'bg-gray-700 border-gray-600 text-white' : 'bg-gray-200 border-gray-300 text-black'}`}
        />
        <button type="submit" className="w-full p-3 bg-green-500 text-white rounded-lg hover:bg-green-600">
          Add AI Rule
        </button>
      </form>
      <ul>
        {rules.map((rule) => (
          <li key={rule._id} className={`mb-6 p-6 border rounded-lg ${isDarkMode ? 'bg-gray-800 border-gray-700' : 'bg-gray-100 border-gray-300'}`}>
            {editingId === rule._id ? (
              <>
                <select
                  value={rule.category}
                  onChange={(e) => setRules(rules.map(r => r._id === rule._id ? { ...r, category: e.target.value } : r))}
                  className={`w-full p-3 mb-4 border rounded-lg focus:outline-none focus:ring ${isDarkMode ? 'bg-gray-700 border-gray-600 text-white' : 'bg-gray-200 border-gray-300 text-black'}`}
                >
                  {CATEGORIES.map(category => (
                    <option key={category} value={category}>{category}</option>
                  ))}
                </select>
                <textarea
                  value={rule.content}
                  onChange={(e) => setRules(rules.map(r => r._id === rule._id ? { ...r, content: e.target.value } : r))}
                  className={`w-full p-3 mb-4 border rounded-lg focus:outline-none focus:ring ${isDarkMode ? 'bg-gray-700 border-gray-600 text-white' : 'bg-gray-200 border-gray-300 text-black'}`}
                />
                <button onClick={() => handleUpdateRule(rule._id)} className="p-3 bg-blue-500 text-white rounded-lg hover:bg-blue-600 mr-2">
                  Save
                </button>
                <button onClick={() => setEditingId(null)} className="p-3 bg-gray-500 text-white rounded-lg hover:bg-gray-600">
                  Cancel
                </button>
              </>
            ) : (
              <>
                <p><strong>Category:</strong> {rule.category}</p>
                <p><strong>Content:</strong> {rule.content}</p>
                <div className="mt-4">
                  <button onClick={() => setEditingId(rule._id)} className="p-3 bg-yellow-500 text-white rounded-lg hover:bg-yellow-600 mr-2">
                    Edit
                  </button>
                  <button onClick={() => handleDeleteRule(rule._id)} className="p-3 bg-red-500 text-white rounded-lg hover:bg-red-600">
                    Delete
                  </button>
                </div>
              </>
            )}
          </li>
        ))}
      </ul>
    </div>
  );
};

export default AIRulesManagement;
