import React, { useRef } from "react";

export const FileImport = ({ onImport, type }) => {
  const fileInputRef = useRef(null);

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onload = (e) => {
        try {
          const data = JSON.parse(e.target.result);
          onImport(data);
        } catch (error) {
          console.error("Error parsing JSON:", error);
          alert("Error parsing JSON file. Please make sure it's a valid JSON.");
        }
      };
      reader.readAsText(file);
    }
  };

  return (
    <div className="mt-4">
      <input
        type="file"
        ref={fileInputRef}
        onChange={handleFileChange}
        accept=".json"
        style={{ display: "none" }}
      />
      <button
        onClick={() => fileInputRef.current.click()}
        className="p-2 bg-purple-500 text-white rounded hover:bg-purple-600 dark:bg-purple-600 dark:hover:bg-purple-700 transition-colors duration-200"
      >
        Import {type} from JSON
      </button>
    </div>
  );
};
