import React, { useEffect, useState } from "react";
import {
  Navigate,
  Route,
  BrowserRouter as Router,
  Routes,
} from "react-router-dom";
import api from "./api/axiosConfig";
import { ForgotPassword } from "./Components/Auth/ForgotPassword";
import { ResetPassword } from "./Components/Auth/ResetPassword";
import { Dashboard } from "./Components/Dashboard";
import { Login } from "./Components/Login";
import { Navbar } from "./Components/Navbar";
import { Register } from "./Components/Register";
import { useTheme } from "./ThemeContext";

const App = () => {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [userRole, setUserRole] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const { isDarkMode, toggleTheme } = useTheme();
  const [qrCodeUrl, setQrCodeUrl] = useState(null);
  const [error, setError] = useState(null);

  useEffect(() => {
    checkAuth();
  }, []);

  useEffect(() => {
    if (isDarkMode) {
      document.body.classList.add("dark");
    } else {
      document.body.classList.remove("dark");
    }
  }, [isDarkMode]);

  useEffect(() => {
    const fetchQRCode = async () => {
      try {
        const response = await api.get(`/qr-code`, {
          responseType: "blob",
          withCredentials: true, // Include this if you need to send cookies
        });

        if (response.status === 200) {
          const url = URL.createObjectURL(response.data);
          setQrCodeUrl(url);
          setError(null);
        } else {
          setError("Failed to fetch QR code");
        }
      } catch (error) {
        if (error.response && error.response.status === 404) {
          setError("QR code not available yet. Please wait.");
        } else {
          setError("Error fetching QR code: " + error.message);
        }
      }
    };

    fetchQRCode();
    const intervalId = setInterval(fetchQRCode, 30000); // Check every 30 seconds

    return () => {
      clearInterval(intervalId);
      if (qrCodeUrl) {
        URL.revokeObjectURL(qrCodeUrl);
      }
    };
  }, []);

  const checkAuth = async () => {
    try {
      const response = await api.get(`/check-auth`, {});
      setIsAuthenticated(response.data.isAuthenticated);
      setUserRole(response.data.role);
    } catch (error) {
      console.error("Error checking authentication:", error);
    } finally {
      setIsLoading(false);
    }
  };

  if (isLoading) {
    return <div>Loading...</div>;
  }

  return (
    <div>
      <Navbar toggleTheme={toggleTheme} isAuthenticated={isAuthenticated} />
      <main>
        <Router>
          <Routes>
            <Route
              path="/login"
              element={
                isAuthenticated ? (
                  <Navigate to="/dashboard" replace />
                ) : (
                  <Login
                    setIsAuthenticated={setIsAuthenticated}
                    setUserRole={setUserRole}
                  />
                )
              }
            />
            <Route
              path="/register"
              element={
                isAuthenticated ? (
                  <Navigate to="/dashboard" replace />
                ) : (
                  <Register />
                )
              }
            />
            <Route path="/forgot-password" element={<ForgotPassword />} />
            <Route path="/reset/:token" element={<ResetPassword />} />

            <Route
              path="/dashboard/*"
              element={
                isAuthenticated ? (
                  <Dashboard
                    setIsAuthenticated={setIsAuthenticated}
                    userRole={userRole}
                  />
                ) : (
                  <Navigate to="/login" replace />
                )
              }
            />
            <Route path="/" element={<Navigate to="/dashboard" replace />} />
          </Routes>
        </Router>
      </main>
    </div>
  );
};

export default App;
